import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import ArajanlatTetelOpciokContent from './../../modules/ArajanlatTetelOpciok/ArajanlatTetelOpciokContent.js';
import MTOArajanlatTetelOpciokContent from './../../modules/MTOArajanlatTetelOpciok/MTOArajanlatTetelOpciokContent.js';
import TextInsertField from './../../components/TextInsertField';
import NumberInsertField from './../../components/NumberInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import ArengedmenyNumberField from './../../componentsSpecial/ArengedmenyNumberField';
import TextFormatter from './../../components/TextFormatter';

class ArajanlatTetelInsert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            arajanlatTetelek_manualis_tetel_sorszam: ''
            , arajanlatTetelek_megnevezes: ''
            , arajanlatTetelek_hivatkozas_megjegyzes: ''
            , arajanlatTetelek_hivatkozas: ''
            , arajanlatTetelek_netto_ar: ''
            , arajanlatTetelek_darabszam: '1'
            , arajanlatTetelek_nettoOsszesen: ''
            

            , arajanlatTetelek_tetel_kibontas: ''
            , ArajanlatTetelOpciok: []
            , MTOArajanlatTetelOpciok: []
            , arajanlatTetelek_arajanlat_id: this.props.additionalParams[1].arajanlatTetelek_arajanlat_id


        }


        this.references = [];
        this.references['arajanlatTetelek_manualis_tetel_sorszam'] = React.createRef();
        this.references['arajanlatTetelek_megnevezes'] = React.createRef();
        this.references['arajanlatTetelek_hivatkozas_megjegyzes'] = React.createRef();
        this.references['arajanlatTetelek_hivatkozas'] = React.createRef();
        this.references['arajanlatTetelek_netto_ar'] = React.createRef();
        this.references['arajanlatTetelek_darabszam'] = React.createRef();
        this.references['arajanlatTetelek_nettoOsszesen'] = React.createRef();
        

        this.references['arajanlatTetelek_tetel_kibontas'] = React.createRef();
        this.references['arajanlatTetelek_MTO'] = React.createRef();
        this.references['arajanlatTetelek_MTO'] = React.createRef();


        this.validators = [];
        this.validators['arajanlatTetelek_manualis_tetel_sorszam'] = {fieldName: 'arajanlatTetelek_manualis_tetel_sorszam', dataType: 'INT', value: this.state.arajanlatTetelek_manualis_tetel_sorszam, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_megnevezes'] = {fieldName: 'arajanlatTetelek_megnevezes', dataType: 'VARCHAR', value: this.state.arajanlatTetelek_megnevezes, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_hivatkozas_megjegyzes'] = {fieldName: 'arajanlatTetelek_hivatkozas_megjegyzes', dataType: 'VARCHAR', value: this.state.arajanlatTetelek_hivatkozas_megjegyzes, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_hivatkozas'] = {fieldName: 'arajanlatTetelek_hivatkozas', dataType: 'VARCHAR', value: this.state.arajanlatTetelek_hivatkozas, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_netto_ar'] = {fieldName: 'arajanlatTetelek_netto_ar', dataType: 'INT', value: this.state.arajanlatTetelek_netto_ar, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_darabszam'] = {fieldName: 'arajanlatTetelek_darabszam', dataType: 'INT', value: this.state.arajanlatTetelek_darabszam, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_nettoOsszesen'] = {fieldName: 'arajanlatTetelek_nettoOsszesen', dataType: 'INT', value: this.state.arajanlatTetelek_nettoOsszesen, mandatory: false, min: null, max: null};

        this.validators['arajanlatTetelek_tetel_kibontas'] = {fieldName: 'arajanlatTetelek_tetel_kibontas', dataType: 'VARCHAR', value: this.state.arajanlatTetelek_tetel_kibontas, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_MTO'] = {fieldName: 'arajanlatTetelek_MTO', dataType: 'MTO', value: this.state.arajanlatTetelek_MTO, mandatory: true, min: null, max: null};
        this.validators['arajanlatTetelek_MTO'] = {fieldName: 'arajanlatTetelek_MTO', dataType: 'MTO', value: this.state.arajanlatTetelek_MTO, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

    }

    componentDidMount() {
        this.reset();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.setState({
            arajanlatTetelek_manualis_tetel_sorszam: ''
            , arajanlatTetelek_megnevezes: ''
            , arajanlatTetelek_hivatkozas_megjegyzes: ''
            , arajanlatTetelek_hivatkozas: ''
            , arajanlatTetelek_netto_ar: ''
            , arajanlatTetelek_darabszam: '1'
            , arajanlatTetelek_tetel_kibontas: ''
            , ArajanlatTetelOpciok: []
            , MTOArajanlatTetelOpciok: []
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getForm = function () {

        var kedvezmenyesAr = this.state.arajanlatTetelek_arengedmeny_ft;
        var isEmpty = this.props.arajanlat_arengedmeny == null || this.props.arajanlat_arengedmeny.trim() == '';
        var notNumber = isNaN(this.props.arajanlat_arengedmeny);
        var isFt = (this.props.arajanlat_arengedmeny >= 100) ? true : false;
        if (isEmpty == false && notNumber == false && isFt == false) {
            var netto = this.state.arajanlatTetelek_netto_ar;
            
            var kedvezmenySzazalek = this.props.arajanlat_arengedmeny;
            var kedvezmenyForint = (netto / 100) * kedvezmenySzazalek;
            var kedvezmenyesAr = netto - kedvezmenyForint;
            this.state.arajanlatTetelek_arengedmeny_ft = kedvezmenyesAr;
            
        }

        if (isEmpty == false && notNumber == false && isFt == true) {
            this.state.arajanlatTetelek_arengedmeny_ft = this.state.arajanlatTetelek_netto_ar;
        }

        if (isEmpty == true) {
            this.state.arajanlatTetelek_arengedmeny_ft = this.state.arajanlatTetelek_netto_ar;
        }
        
        var darabszam =(this.state.arajanlatTetelek_darabszam==null ||this.state.arajanlatTetelek_darabszam =='')?1:this.state.arajanlatTetelek_darabszam;
        this.state.arajanlatTetelek_nettoOsszesen = this.state.arajanlatTetelek_netto_ar*darabszam;
        


        return <Container>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_manualis_tetel_sorszam')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'arajanlatTetelek_manualis_tetel_sorszam'} 
                    value={this.state.arajanlatTetelek_manualis_tetel_sorszam}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_manualis_tetel_sorszam']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_darabszam')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'arajanlatTetelek_darabszam'} 
                    value={this.state.arajanlatTetelek_darabszam}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_darabszam']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            
            
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_megnevezes')}</Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField
        
                    id={'arajanlatTetelek_megnevezes'} 
                    value={this.state.arajanlatTetelek_megnevezes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_megnevezes']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_hivatkozas_megjegyzes')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'arajanlatTetelek_hivatkozas_megjegyzes'} 
                    value={this.state.arajanlatTetelek_hivatkozas_megjegyzes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_hivatkozas_megjegyzes']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_hivatkozas')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'arajanlatTetelek_hivatkozas'} 
                    value={this.state.arajanlatTetelek_hivatkozas}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_hivatkozas']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_netto_ar')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'arajanlatTetelek_netto_ar'} 
                    value={this.state.arajanlatTetelek_netto_ar}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_netto_ar']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_nettoOsszesen')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'arajanlatTetelek_nettoOsszesen'} 
                    value={this.state.arajanlatTetelek_nettoOsszesen}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_nettoOsszesen']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_tetel_kibontas')}</Col>
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextFormatter
                    sablonKivalasztoval={false}
                    id={'arajanlatTetelek_tetel_kibontas'} 
                    value={this.state.arajanlatTetelek_tetel_kibontas}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_tetel_kibontas']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        
            <Row>
                <Col style={{textAlign: 'right'}}>
                <div>
                    <MTOArajanlatTetelOpciokContent   
                        value={this.state.MTOArajanlatTetelOpciok} 
                        setBack={this.setBack.bind(this)} 
                        additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
                        editable={true}
                        formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                        theme={this.props.theme}
                        arajanlatTetelek_darabszam={this.state.arajanlatTetelek_darabszam}
                        />
                </div>
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitleBold('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitleBold(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                stateClone[property] = additionalParam[property];
            }
        }
        return stateClone;
    }

    insert = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        var params = this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/ArajanlatTetel/arajanlatTetelekFormInsert.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(params)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.state.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{minWidth: 1100}}>
            <div className={'popupWrapper' + className} id="ArajanlatTetelInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('ArajanlatTetelInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.insert.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        <Button className={'btn-sm'} id={'save_new'} onClick={this.insert.bind(this)} variant="info" style={{marginTop: '5px'}}>{lang.getTitle('new')}</Button>      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default ArajanlatTetelInsert;

