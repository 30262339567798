import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'



import PagingToolbar from './../../components/PagingToolbar';

import {Button} from 'react-bootstrap';

import InsertForm from './MunkaBeosztasCimekInsertForm.js';
import UpdateForm from './MunkaBeosztasCimekUpdateForm.js';
import ViewForm from './MunkaBeosztasCimekViewForm.js';
import Dialog from './../../components/Dialog.js';
import ActionCell from './../../components/ActionCell';


import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');



class MunkaBeosztasCimekTable extends Component {

    constructor(props) {
        super(props);

        this.NONE = "NONE";

        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";


        this.DIALOG_INFO = "INFO";
        this.DIALOG_ALERT = "ALERT";
        this.DIALOG_CONFIRMATION = "CONFIRMATION";
        var columnNames = ['action_cell','munkaBeosztasCimek_id', 'munkaBeosztasCimek_munka_beosztas_id', 'munkaBeosztasCimek_ugyfel_id','munkaBeosztasCimek_ugyfel_nev', 'munkaBeosztasCimek_irsz', 'munkaBeosztasCimek_telepules', 'munkaBeosztasCimek_telepulesNev', 'munkaBeosztasCimek_cim', 'munkaBeosztasCimek_megjegyzes'];
        this.columnStateHandler = ajax.columnStateHandler(columnNames, "MunkaBeosztasCimek", "munkaBeosztasCimek");

        this.state = {
            columnOrder: columnNames,
            theme: this.props.theme,
            openedForm: this.NONE, // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRow: null,
            sortInfo: null,
            groupBy: false,
            filterValue: null,
            additionalParams: [],
            requestProps: null,
            skip: 0,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 50,
                showingRowFrom: 0,
                showingRowTo: 50,
                totalRowCount: 0,
                skip: 0
            },
            dialogType: this.NONE,
            dialogText: ''
        };
        this.renderPaginationToolbar = null;
        this.ref = React.createRef();

    }
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({data: this.props.value});
        }
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.openedForm != nextState.openedForm) {
            return true;
        }
        if (nextProps.theme != nextState.theme) {
            return true;
        }
        return false; // equals() is your implementation
    }

    setView = function (e) {

        var formName = e.target.id;

        var rowSelectionNeed = false;
        var hasSelectedRow = true;

        if (formName == this.FORM_DELETE || formName == this.FORM_UPDATE || formName == this.FORM_VIEW) {
            rowSelectionNeed = true;
        }
        if (this.state.selectedRow == null || this.state.selectedRow == '') {
            hasSelectedRow = false;
        }
        if (rowSelectionNeed == true && hasSelectedRow == false) {
            this.setState({openedForm: this.DIALOG_ALERT, dialogText: lang.getTitle("ROW_SELECTION_NEED")});
        } else {
            var dialogText = "";
            if (formName == this.FORM_DELETE) {
                dialogText = lang.getTitle("DELETE_CONFIRMATION");
            }
            this.setState({openedForm: formName, dialogText: dialogText});
        }



    }

    closeForm = function () {
        this.setState({openedForm: this.NONE});
    }

    customPaging = function (pagingProps) {
        return <PagingToolbar blackTheme={this.props.theme} tableReload={this.reload.bind(this)} paging={this.state.paging} />
    }
    reload = function (skip) {
        this.state.paging.skip = skip;
        this.forceUpdate();
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    addForeignKeyFilter = function (filterValues) {

        var additionalParams = this.props.additionalParams;

        if (typeof additionalParams == 'undefined') {
            return filterValues;
        }

        if (additionalParams == null) {
            return filterValues;
        }

        if (additionalParams.length == null) {
            return filterValues;
        }

        for (var ap = 0; ap < additionalParams.length; ap++) {

            var additionalParam = additionalParams[ap];

            for (const property in additionalParam) {

                var filter = null;
                for (var f = 0; f < filterValues.length; f++) {
                    var currentFilter = filterValues[f];
                    if (currentFilter.name == property) {
                        filter = currentFilter;
                    }
                }

                if (filter != null) {
                    filter.operator = 'eq';
                    filter.type = 'number';
                    filter.value = additionalParam[property];
                } else {
                    filter = {
                        name: property,
                        operator: 'eq',
                        type: 'number',
                        value: additionalParam[property]
                    };
                    filterValues.push(filter);
                }
            }

        }
        return filterValues;
    }

    dataLoader = function ( { skip, limit, sortInfo, groupBy, filterValue }){
        this.state.paging.skip = (typeof this.state.paging.skip == 'undefined') ? 0 : this.state.paging.skip;
        var state = this.state;
        filterValue = this.addForeignKeyFilter(filterValue);
        this.state.requestProps = {skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue};
        try {
            const url = ajax.getUrlBase() + "modules/MunkaBeosztasCimek/munkaBeosztasCimekTableSelect.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        
                        var totalCount = response.count;
                        var data = response.data;
                        state.paging.totalRowCount = totalCount;
                        var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                        state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                        state.paging.showingRowFrom = skip;
                        state.paging.showingRowTo = skip + state.pageRowCount;

                        return Promise.resolve({data, count: parseInt(totalCount), skip: state.paging.skip});
                    }).catch(function (jsonError) {
                        
                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                state.defaultUpdateBlocked = false;
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
    }
    }

    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/MunkaBeosztasCimek/munkaBeosztasCimekFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({munkaBeosztasCimek_id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({openedForm: 'HIDDEN', selectedRow: null});
                    }).catch(function (jsonError) {
                        
                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            
            console.error("Fetch exception:", String(ex));
        }
    }

    onSelectionChange = function (selection) {
        this.setState({selectedRow: selection.selected});
    }

    onRowClick = function (rowProps, event) {

        if (typeof rowProps == 'undefined') {
            return false;
        }

        if (rowProps == null) {
            return false;
        }

        if (typeof rowProps.data == 'undefined') {
            return false;
        }

        if (rowProps.data == null) {
            return false;
        }

        if (typeof rowProps.data.munkaBeosztasCimek_id == 'undefined') {
            return false;
        }

        if (rowProps.data.munkaBeosztasCimek_id == null) {
            return false;
        }
        this.setState({selectedRow: rowProps.data.munkaBeosztasCimek_id});
    }

    hasSelectedRow = function () {
        if (typeof this.state.selectedRow == 'undefined') {
            return false;
        }
        if (this.state.selectedRow == null) {
            return false;
        }


        if (this.state.selectedRow == -1) {
            return false;
        }
        return true;
    }

    isViewForm = function () {

        if (typeof this.props.formType == 'undefined') {
            return false;
        }
        if (this.props.formType == null) {
            return false;
        }
        if (this.props.formType == '') {
            return false;
        }
        if (this.props.formType == this.FORM_VIEW) {
            return true;
        }
        return false;
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.columnStateHandler.storColumnVisibility(column.name, visible);
        this.forceUpdate();

    }

    render() {
        var isViewForm = this.isViewForm();
        var columns = [
           
            {name: 'munkaBeosztasCimek_id', sortable: true, header: lang.getTitle('munkaBeosztasCimek_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkaBeosztasCimek_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true}
            , {name: 'munkaBeosztasCimek_munka_beosztas_id', sortable: true, header: lang.getTitle('munkaBeosztasCimek_munka_beosztas_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkaBeosztasCimek_munka_beosztas_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'munkaBeosztasCimek_ugyfel_id', sortable: true, header: lang.getTitle('munkaBeosztasCimek_ugyfel_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkaBeosztasCimek_ugyfel_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'munkaBeosztasCimek_ugyfel_nev', sortable: true, header: lang.getTitle('munkaBeosztasCimek_ugyfel_nev'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkaBeosztasCimek_ugyfel_nev', 150), defaultVisible: true}
            , {name: 'munkaBeosztasCimek_irsz', sortable: true, header: lang.getTitle('munkaBeosztasCimek_irsz'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkaBeosztasCimek_irsz', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true}
            , {name: 'munkaBeosztasCimek_telepules', sortable: true, header: lang.getTitle('munkaBeosztasCimek_telepules'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkaBeosztasCimek_telepules', 150), defaultVisible: true}
            , {name: 'munkaBeosztasCimek_telepulesNev', sortable: true, header: lang.getTitle('munkaBeosztasCimek_telepulesNev'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkaBeosztasCimek_telepulesNev', 150), defaultVisible: false}
            , {name: 'munkaBeosztasCimek_cim', sortable: true, header: lang.getTitle('munkaBeosztasCimek_cim'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkaBeosztasCimek_cim', 150), defaultVisible: true,flex:1}
            , {name: 'munkaBeosztasCimek_megjegyzes', sortable: true, header: lang.getTitle('munkaBeosztasCimek_megjegyzes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkaBeosztasCimek_megjegyzes', 150), defaultVisible: true}

        ];

        const filters = [
            {name: 'munkaBeosztasCimek_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'munkaBeosztasCimek_munka_beosztas_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'munkaBeosztasCimek_ugyfel_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'munkaBeosztasCimek_ugyfel_nev', operator: 'contains', type: 'string', value: ''}
            , {name: 'munkaBeosztasCimek_irsz', operator: 'eq', type: 'number', value: ''}
            , {name: 'munkaBeosztasCimek_telepules', operator: 'contains', type: 'string', value: ''}
            , {name: 'munkaBeosztasCimek_telepulesNev', operator: 'contains', type: 'string', value: ''}
            , {name: 'munkaBeosztasCimek_cim', operator: 'contains', type: 'string', value: ''}
            , {name: 'munkaBeosztasCimek_megjegyzes', operator: 'contains', type: 'string', value: ''}

        ]

        columns = this.columnStateHandler.setColumnsVisibility(columns);

        var openedForm = "";
        if (this.state.openedForm == this.FORM_VIEW) {
            openedForm = <ViewForm theme={this.props.theme} key={'munkaBeosztasCimek_KEY_VIEW'} formType={this.FORM_VIEW} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_INSERT) {
            openedForm = <InsertForm  theme={this.props.theme} key={'munkaBeosztasCimek_KEY_INSERT'} formType={this.FORM_INSERT} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_UPDATE) {

            var hasSelectedRow = this.hasSelectedRow();
            if (hasSelectedRow == true) {
                openedForm = <UpdateForm theme={this.props.theme} key={'munkaBeosztasCimek_KEY_UPDATE'} formType={this.FORM_UPDATE} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
            } else {
                console.log("Nincs kiválasztott sor");
                // Alert window
            }
        }

        if (this.state.openedForm == this.FORM_DELETE) {
            openedForm = <Dialog 
                type={this.DIALOG_CONFIRMATION} 
                dialogText={this.state.dialogText} 
                callback={this.delete.bind(this)}
                cancel={() => this.setView({target: {id: this.NONE}})}
            
                theme={this.props.theme}
                />;
        }
        if (this.state.openedForm == this.DIALOG_ALERT) {
            openedForm = <Dialog 
                type={this.DIALOG_ALERT} 
                dialogText={this.state.dialogText} 
                callback={() => this.setView({target: {id: this.NONE}})}
                theme={this.props.theme}
                />;
        }


        if (this.renderPaginationToolbar == null) {
            this.renderPaginationToolbar = this.customPaging.bind(this)
        }

        var buttons = [];

        var viewButton = <div  key={'munkaBeosztasCimek_KEY_VIEW_BUTTON'} >&nbsp;<Button className={'btn-sm'} id={this.FORM_VIEW} onClick={this.setView.bind(this)} variant="info">{lang.getTitle('View')}</Button></div>;
        var insertButton = <div key={'munkaBeosztasCimek_KEY_INSERT_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_INSERT} onClick={this.setView.bind(this)} variant="success">{lang.getTitle('Insert')}</Button></div>;
        var updateButton = <div key={'munkaBeosztasCimek_KEY_UPDATE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_UPDATE} onClick={this.setView.bind(this)} variant="warning">{lang.getTitle('Update')}</Button></div>;
        var deleteButton = <div key={'munkaBeosztasCimek_KEY_DELETE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_DELETE} onClick={this.setView.bind(this)} variant="danger">{lang.getTitle('Delete')}</Button></div>;

        var isViewForm = this.isViewForm();

        if (isViewForm) {
            buttons = [viewButton];
        } else {
            buttons = [viewButton, insertButton, updateButton, deleteButton];
        }
        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';

        return (
                <div id="MunkaBeosztasCimekTable">
                    <div style={{display: "flex"}}>
                        {buttons}
                    </div>
                    <ReactDataGrid 
                        idProperty="munkaBeosztasCimek_id" 
                        i18n={ajax.getI18n()}
                        columns={this.columnStateHandler.myColumnsReorder(columns)}
                        dataSource={this.dataLoader.bind(this)}
                        defaultFilterValue={filters}
                        enableSelection={true}
                
                        onRowClick={this.onRowClick.bind(this)}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                        theme={theme}
                        style={{height: 350, marginTop: 10}}    
                
                        defaultLimit={100}
                        pagination={true}
                        renderPaginationToolbar={this.renderPaginationToolbar}
                        skip={this.state.skip}
                        limit={this.state.paging.pageRowCount}
                
                        onColumnResize={this.columnStateHandler.onColumnResize.bind(this)}  
                        onColumnOrderChange={this.columnStateHandler.onColumnOrderChange.bind(this)}
                        onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
                
                        />
                    {openedForm}            
                </div>
                );
    }
}

export default MunkaBeosztasCimekTable;
