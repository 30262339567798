import * as lang from "./lang.js";

// Installálás npm install --legacy-peer-deps
// package.json ban beilleszteni
// "homepage":"/titar_front/",
// "homepage":"http://localhost/titar_front/build",
// "homepage":"http://www.devszerver.hu/titar_front/build",
// "homepage":"https://titar.hu/titar_front/",

// Szerver oldalon: Utils.php  const DEV_MODE = false ;

// connect.php ben: HA ÉLESBEN VAN 
// $testUserId=-1;
// !!!!!!!!!!! HA NINCS  ÉLESBEN AKKOR BE KELL ÁLLÍTANI A USER ID-T !!!!!!!!!!!

// Menu.js-ben:
// isMenuHidden = function (menuId) {
// return false;

// Contents.js-ben:
// loggedIn: false,

// Az auto login miatt
// INSERT INTO `titar`.`userAutoLogin` (`user_id`, `ipAddress`, `cookie`, `startTime`) VALUES ('1', '192.168.0.13', 'V63AEgwaabjIMaAniT3PxKtmDhj85o8OwezEyicE', now());

// A getUrlBase() ben a url-t átállitani 

export function getUrlBase() {
  // return "http://localhost/titar_back/";
   return "/titar_back/";
 // return "http://192.168.0.15/titar_back/";
}

export function getI18n() {
    return {
        pageText: 'Lap ',
        ofText: ' Összesen ',
        perPageText: 'Sorok száma',
        showingText: 'Showing ',
        clearAll: 'Összes törlése',
        clear: 'Törlés',
        showFilteringRow: 'Szűrés megjelenítése',
        hideFilteringRow: 'Szűrés elrejtése',
        enable: 'Engedélyezve',
        disable: 'Tiltva',
        sortAsc: 'Növekvő rendezés', //'Sort ascending',
        sortDesc: 'Csökkenő rendezés', //'Sort descending',
        unsort: 'Rendezetlen',
        group: 'Csoportosít',
        ungroup: 'Csoport bontása',
        lockStart: 'Rögzítés kezdete',
        lockEnd: 'Rögzítés vége',
        unlock: 'Rögzítés megszüntetése',
        columns: 'Oszlopok',
        contains: 'Tartalmazza', // 'Contains',
        startsWith: 'Elejétől',
        endsWith: 'Végétől',
        notContains: 'Nem tartalmazza',
        inlist: 'Listában',
        notinlist: 'Nincs a listában',
        neq: 'Nem egyenlő',
        inrange: 'Intervallumban',
        notinrange: 'Intervallumon kívül',
        eq: 'Egyenlő',
        notEmpty: 'Nem üres',
        empty: 'Üres',
        lt: 'Kisebb',
        lte: 'Kisebb vagy egyenlő',
        gt: 'Nagyobb',
        gte: 'Nagyobb vagy egyenlő',
        before: 'Előtte',
        beforeOrOn: 'Előtte inkluzív',
        afterOrOn: 'Utána inkluzív',
        after: 'Utána',
        start: 'Kezdés',
        end: 'Befejezés',
        dragHeaderToGroup: 'Húzza a fejlécre a csoportosításhoz',
        noRecords: 'Nincs találat',
        'calendar.todayButtonText': 'Mai nap',
        'calendar.clearButtonText': 'Törlés',
        'calendar.okButtonText': 'OK',
        'calendar.cancelButtonText': 'Mégsem',
        invalidDate: ''
    }
}




export  function request(requestName, requestData, callbacks) {

    try {
        fetch('/titar_back/' + requestName, {
            method: "POST",
            credentials: "include",
            headers: new Headers({"Content-Type": "application/json"}),
            body: JSON.stringify(requestData)
        }).then(function (response) {
            if (response.ok) {
                response.json().then(function (responseJson) {
                    if (responseJson.data != null && responseJson.success != null && responseJson.success === true) {
                        callbacks.success(responseJson.data);
                    } else {
                        callbacks.fault(responseJson.error);
                    }
                }).catch(function (jsonError) {
                    console.error("Fetch JSON error:", jsonError);
                    if (null == jsonError) {
                        callbacks.error();
                    } else {
                        callbacks.error(String(jsonError));
                    }
                });
            } else {
                console.error("HTTP status error:", response.statusText);
                callbacks.error(response.statusText);
            }
        }
        ).catch(function (error) {
            console.error("Fetch error:", error);
            if (null == error) {
                callbacks.error();
            } else {
                callbacks.error(String(error));
            }
        });
    } catch (ex) {
        console.error("Fetch exception:", ex);
        callbacks.error(String(ex));
    }
}

export  function postRequest(requestName, requestData, callbacks) {
    var formBody = new FormData();
    for (var property in requestData) {
        if (property === 'images') {
            var files = requestData[property];
            for (var i = 0; i < files.length; i++) {
                formBody.append("images[]", files[i], files[i].name);
            }
        } else if (property === 'files') {
            var files = requestData[property];
            for (var i = 0; i < files.length; i++) {
                formBody.append("files[]", files[i], files[i].name);
            }
        } else if (property === 'existingImages') {
            var existingImages = requestData[property];
            for (var i = 0; i < existingImages.length; i++) {
                if (existingImages[i].delete === 1) {
                    formBody.append("existingImages[]", existingImages[i].id);
                }
            }
        } else if (property === 'existingFiles') {
            var existingFiles = requestData[property];
            for (var i = 0; i < existingFiles.length; i++) {
                if (existingFiles[i].delete === 1) {
                    formBody.append("existingFiles[]", existingFiles[i].id);
                }
            }
        } else {
            formBody.append(property, requestData[property]);
        }

    }

    try {
        fetch('/titar_back/' + requestName, {
            method: "POST",
            body: formBody
        }).then(function (response) {
            if (response.ok) {
                response.json().then(function (responseJson) {
                    if (responseJson.data != null && responseJson.success != null && responseJson.success === true) {
                        callbacks.success(responseJson);
                    } else {
                        callbacks.fault(responseJson);
                    }
                }).catch(function (jsonError) {
                    callbacks.error('json_error');
                });
            } else {
                callbacks.error("HTTP status error:" + response.statusText);
            }
        }
        ).catch(function (error) {
            callbacks.error("Fetch error:" + error);
        });
    } catch (ex) {
        callbacks.error("Fetch exception:" + String(ex));
    }
}


export  function dateTimeToServerAsString(date) {
    if (typeof date === 'undefined' || date === null || (!(date instanceof Date))) {
        return null
    }

    var utcYear = date.getUTCFullYear();
    var utcMonth = date.getUTCMonth() + 1;
    var utcDate = date.getUTCDate();
    utcMonth = (utcMonth < 10) ? '0' + utcMonth.toString() : utcMonth.toString();
    utcDate = (utcDate < 10) ? '0' + utcDate.toString() : utcDate.toString();
    var utcHours = date.getUTCHours();
    var utcMinutes = date.getUTCMinutes();
    var utcSeconds = date.getUTCSeconds();
    utcHours = (utcHours < 10) ? '0' + utcHours.toString() : utcHours.toString();
    utcMinutes = (utcMinutes < 10) ? '0' + utcMinutes.toString() : utcMinutes.toString();
    utcSeconds = (utcSeconds < 10) ? '0' + utcSeconds.toString() : utcSeconds.toString();
    return utcYear + '-' + utcMonth + '-' + utcDate + ' ' + utcHours + ':' + utcMinutes + ":" + utcSeconds;
}

export function dateTimeStringFromServerToDate(date) {

    if (typeof date === 'undefined' || date === null || typeof date !== 'string') {
        return null;
    }

    var dateAndTime = date.split(' ');
    if (dateAndTime.length < 2) {
        return null;
    }

    var datePart = dateAndTime[0];
    var timePart = dateAndTime[1];
    var dateElements = datePart.split('-');
    var timeElements = timePart.split(':');
    if (dateElements.length < 3 || timeElements.length < 3) {
        return null;
    }
    var year = dateElements[0];
    var month = dateElements[1];
    var date = dateElements[2];
    var hours = timeElements[0];
    var min = timeElements[1];
    var sec = timeElements[2];
    var tempDate = new Date();
    tempDate.setFullYear(year);
    tempDate.setMonth(month - 1);
    tempDate.setDate(date);
    tempDate.setHours(hours);
    tempDate.setMinutes(min);
    tempDate.setSeconds(sec);
    var localDate = new Date(Date.UTC(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), tempDate.getHours(), tempDate.getMinutes(), tempDate.getSeconds()));
    return localDate;
}

export function dateTimeStringObject(date) {


    if (typeof date === 'undefined' || date === null || (!(date instanceof Date))) {
        return null;
    }

    var fullYear = date.getFullYear();
    var month = (date.getMonth() + 1);
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var fullYearString = fullYear.toString();
    var monthString = (month < 10) ? '0' + month.toString() : month.toString();
    var dayString = (day < 10) ? '0' + day.toString() : day.toString();
    var dateString = fullYearString + '-' + monthString + '-' + dayString;
    var hourString = (hours < 10) ? '0' + hours.toString() : hours.toString();
    var minuteString = (minutes < 10) ? '0' + minutes.toString() : minutes.toString();
    var secondString = (seconds < 10) ? '0' + seconds.toString() : seconds.toString();
    var timeString = hourString + ':' + minuteString + ':' + secondString;
    return  dateString + " " + timeString;
}

export function stringDateTimeObject(dateString) {
    if (typeof dateString === 'undefined' || dateString === null || dateString.trim() === '') {
        return null;
    }
    return new Date(dateString);
}


export function  Validator() {
    return {

        formValidator: function (references, validators, state) {

            var validForm = true;
            for (var key in validators) {

                var validator = validators[key];

                var fieldName = validator.fieldName;
                var dataType = validator.dataType;
                var value = state[fieldName];
                var mandatory = validator.mandatory;
                var min = validator.min;
                var max = validator.max;




                var valid = this.fieldValidator(dataType, value, mandatory, min, max);

                if (valid && typeof validator.validatorFunction != 'undefined') {
                    var checkResult = validator.validatorFunction(value);
                    if (checkResult == false) {
                        valid = false;
                    }
                }


                if (valid == false) {
                    validForm = false;
                    console.log('Érvénytelen adat', validator, fieldName);
                    this.fieldDecorator(validator, true, references[fieldName]);
                } else {
                    this.fieldDecorator(validator, false, references[fieldName]);
                }
            }
            return validForm;
        },
        fieldDecorator: function (validator, error, reference) {

            if (reference == null) {
                return false;
            }

            if (typeof reference.current == 'undefined' || reference.current == null) {
                return false;
            }
            if (typeof reference.current.style == 'undefined' || reference.current.style == null) {
                return false;
            }

            var fieldName = validator.fieldName;
            var dataType = validator.dataType;
            var mandatory = validator.mandatory;
            var min = validator.min;
            var max = validator.max;

            var errorString = "";
            if (mandatory) {
                errorString += lang.getTitle("MANDATORY_PARAMETER");
                errorString += "\n";
            }
            if (min !== null) {
                errorString += lang.getTitle("MIN_VALUE");
                errorString += " : ";
                errorString += min;
                errorString += ";\n";
            }
            if (max !== null) {
                errorString += lang.getTitle("MAX_VALUE");
                errorString += " : ";
                errorString += max;
                errorString += ";\n";
            }


            if (error) {
                reference.current.title = errorString;
                reference.current.style.borderBottom = "dotted 2px red";
            } else {
                reference.current.title = "";
                reference.current.style.borderBottom = "solid 1px grey";
            }

        },
        fieldValidator: function (dataType, value, mandatory, min, max) {
            switch (dataType) {
                case "BIGINT":
                    return this.ValidatorBIGINT(value, mandatory, min, max);
                    break;
                case "BINARY":
                    return this.ValidatorBINARY(value, mandatory, min, max);
                    break;
                case "BIT":
                    return this.ValidatorBIT(value, mandatory, min, max);
                    break;
                case "BLOB":
                    return this.ValidatorBLOB(value, mandatory, min, max);
                    break;
                case "CHAR":
                    return this.ValidatorCHAR(value, mandatory, min, max);
                    break;
                case "DATE":
                    return this.ValidatorDATE(value, mandatory, min, max);
                    break;
                case "DATETIME":
                    return this.ValidatorDATETIME(value, mandatory, min, max);
                    break;
                case "DECIMAL":
                    return this.ValidatorDECIMAL(value, mandatory, min, max);
                    break;
                case "DOUBLE":
                    return this.ValidatorDOUBLE(value, mandatory, min, max);
                    break;
                case "FLOAT":
                    return this.ValidatorFLOAT(value, mandatory, min, max);
                    break;
                case "INT":
                    return this.ValidatorINTEGER(value, mandatory, min, max);
                    break;
                case "INTEGER":
                    return this.ValidatorINTEGER(value, mandatory, min, max);
                    break;
                case "LONGBLOB":
                    return this.ValidatorLONGBLOB(value, mandatory, min, max);
                    break;
                case "MEDIUMBLOB":
                    return this.ValidatorMEDIUMBLOB(value, mandatory, min, max);
                    break;
                case "MEDIUMINT":
                    return this.ValidatorMEDIUMINT(value, mandatory, min, max);
                    break;
                case "SMALLINT":
                    return this.ValidatorSMALLINT(value, mandatory, min, max);
                    break;
                case "TIME":
                    return this.ValidatorTIME(value, mandatory, min, max);
                    break;
                case "TIMESTAMP":
                    return this.ValidatorTIMESTAMP(value, mandatory, min, max);
                    break;
                case "TINYBLOB":
                    return this.ValidatorTINYBLOB(value, mandatory, min, max);
                    break;
                case "TINYINT":
                    return this.ValidatorTINYINT(value, mandatory, min, max);
                    break;
                case "VARBINARY":
                    return this.ValidatorVARBINARY(value, mandatory, min, max);
                    break;
                case "VARCHAR":
                    return this.ValidatorVARCHAR(value, mandatory, min, max);
                    break;
                case "YEAR":
                    return this.ValidatorYEAR(value, mandatory, min, max);
                    break;
                default:
                    return  true;
                    break;
            }
        },
        numberValidator: function (value, mandatory, min, max) {

            if (mandatory && (typeof value == 'undefined' || value == null || value === '')) {
                return false;
            }
            if (value == null) {
                return true;
            }
            if (value === '') {
                return true;
            }
            if (isNaN(Number(value))) {
                return false;
            }

            if (min != null && value < min) {
                return false;
            }

            if (max != null && value > max) {
                return false;
            }

            return true;
        },
        dateValidator: function (value, mandatory, min, max) {
            if (mandatory && (typeof value == 'undefined' || value == null || value == '')) {
                return false;
            }
            if (value == null) {
                return true;
            }
            if (value == '') {
                return true;
            }
            var isValidDate = Date.parse(value);
            if (isNaN(isValidDate)) {
                return false;
            }

            if (min != null && new Date(value).getTime() < new Date(min).getTime()) {
                return false;
            }

            if (max != null && new Date(value).getTime() > new Date(max).getTime()) {
                return false;
            }

            return true;
        },
        timeValidator: function (value, mandatory, min, max) {
            if (mandatory && (typeof value == 'undefined' || value == null || value == '')) {
                return false;
            }
            if (value == null) {
                return true;
            }
            if (value == '') {
                return true;
            }
            var isValidDate = Date.parse('2000-01-01 ' + value);
            if (isNaN(isValidDate)) {
                return false;
            }

            if (min != null && new Date('2000-01-01 ' + value).getTime() < new Date('2000-01-01 ' + min).getTime()) {
                return false;
            }

            if (max != null && new Date('2000-01-01 ' + value).getTime() > new Date('2000-01-01 ' + max).getTime()) {
                return false;
            }

            return true;
        },
        lengthValidator: function (value, mandatory, min, max) {
            if (mandatory && (typeof value == 'undefined' || value == null || typeof value.trim == 'undefined' || value.trim() == '')) {
                return false;
            }
            if (value == null) {
                return true;
            }
            if (value == '') {
                return true;
            }
            if (min != null && value.length < min) {
                return false;
            }

            if (max != null && value.length > max) {
                return false;
            }
            return true;
        },
        ValidatorBIGINT: function (value, mandatory, min, max) {
            return this.numberValidator(value, mandatory, min, max);
        },
        ValidatorBINARY: function (value, mandatory, min, max) {
            return this.numberValidator(value, mandatory, min, max);
        },
        ValidatorBIT: function (value, mandatory, min, max) {
            return this.numberValidator(value, mandatory, min, max);
        },
        ValidatorBLOB: function (value, mandatory, min, max) {
            return this.lengthValidator(value, mandatory, min, max);
        },
        ValidatorCHAR: function (value, mandatory, min, max) {
            return this.lengthValidator(value, mandatory, min, max);
        },
        ValidatorDATE: function (value, mandatory, min, max) {
            return this.dateValidator(value, mandatory, min, max);
        },
        ValidatorDATETIME: function (value, mandatory, min, max) {
            return this.dateValidator(value, mandatory, min, max);
        },
        ValidatorDECIMAL: function (value, mandatory, min, max) {
            return this.numberValidator(value, mandatory, min, max);
        },
        ValidatorDOUBLE: function (value, mandatory, min, max) {
            return this.numberValidator(value, mandatory, min, max);
        },
        ValidatorFLOAT: function (value, mandatory, min, max) {
            return this.numberValidator(value, mandatory, min, max);
        },
        ValidatorINTEGER: function (value, mandatory, min, max) {
            return this.numberValidator(value, mandatory, min, max);
        },
        ValidatorLONGBLOB: function (value, mandatory, min, max) {
            return this.lengthValidator(value, mandatory, min, max);
        },
        ValidatorMEDIUMBLOB: function (value, mandatory, min, max) {
            return this.lengthValidator(value, mandatory, min, max);
        },
        ValidatorMEDIUMINT: function (value, mandatory, min, max) {
            return this.numberValidator(value, mandatory, min, max);
        },
        ValidatorSMALLINT: function (value, mandatory, min, max) {
            return this.numberValidator(value, mandatory, min, max);
        },
        ValidatorTIME: function (value, mandatory, min, max) {
            return this.timeValidator(value, mandatory, min, max);
        },
        ValidatorTIMESTAMP: function (value, mandatory, min, max) {
            return this.dateValidator(value, mandatory, min, max);
        },
        ValidatorTINYBLOB: function (value, mandatory, min, max) {
            return this.lengthValidator(value, mandatory, min, max);
        },
        ValidatorTINYINT: function (value, mandatory, min, max) {
            return this.numberValidator(value, mandatory, min, max);
        },
        ValidatorVARBINARY: function (value, mandatory, min, max) {
            return this.lengthValidator(value, mandatory, min, max);
        },
        ValidatorVARCHAR: function (value, mandatory, min, max) {
            return this.lengthValidator(value, mandatory, min, max);
        },
        ValidatorYEAR: function (value, mandatory, min, max) {
            return this.numberValidator(value, mandatory, min, max);
        }
    }

}

export function columnStateHandler(originalColumnNames, moduleName, tableName) {
    return {

        onColumnResize: function (resized) {
            localStorage.setItem(moduleName + "_" + tableName + '#' + resized.column.name, resized.width);
        },
        getColumnWidth: function (columnName, defaultSize) {
            var storedSize = localStorage.getItem(moduleName + "_" + tableName + "#" + columnName);
            return (storedSize != null) ? storedSize * 1.0 : defaultSize;
        },
        onColumnOrderChange: function (ordered) {
            localStorage.setItem(moduleName + "_" + tableName + '#customColumnOrder', ordered);
        },
        getColumnOrder: function () {
            var storedOrder = localStorage.getItem(moduleName + "_" + tableName + '#customColumnOrder');

            if (typeof storedOrder == 'undefined') {
                return originalColumnNames;
            }

            if (storedOrder == null) {
                return originalColumnNames;
            }
            if (storedOrder == '') {
                return originalColumnNames;
            }

            var storedOrderArray = storedOrder.split(",");

            if (storedOrderArray.length == originalColumnNames.length) {
                return  storedOrderArray;
            } else {
                console.log("Az új oszlopsorrend tárolásához egyeznie kell a tárolt és a létrehozott oszlopok számához.");
            }

            return originalColumnNames;
        },
        myColumnsReorder: function (columns) {
            var orderedColumns = [];
            var order = this.getColumnOrder();

            for (var o = 0; o < order.length; o++) {
                for (var c = 0; c < columns.length; c++) {
                    var column = columns[c];
                    if (order[o] == column.name) {
                        orderedColumns.push(column);
                    }
                }
            }
            return orderedColumns;
        },
        storColumnVisibility: function (columnName, visible) {
            localStorage.setItem(moduleName + "_" + tableName + '#visible_' + columnName, visible);
        },
        getColumnVisibility: function (columnName) {

            var visible = localStorage.getItem(moduleName + "_" + tableName + '#visible_' + columnName);

            if (typeof visible == 'undefined') {
                return true;
            }
            if (visible == null) {
                return true;
            }
            if (visible == '') {
                return true;
            }

            if (visible == 'false') {
                return false;
            }
            if (visible == 'true') {
                return true;
            }
            return visible;
        },
        setColumnsVisibility: function (columns) {
            for (var i = 0; i < columns.length; i++) {
                columns[i]['visible'] = (columns[i].defaultVisible == false) ? false : this.getColumnVisibility(columns[i].name);
            }
            return columns;
        },
        saveLimit: function (limit) {
            localStorage.setItem(moduleName + "_" + tableName + "_limit", limit);
        },
        loadLimit: function () {
            var limit = localStorage.getItem(moduleName + "_" + tableName + "_limit");
            if (typeof limit == 'undefined') {
                return 50;
            }
            if (limit == null) {
                return 50;
            }
            if (limit == '') {
                return 50;
            }
            return limit;
        },
        saveSortInfo: function (sortInfo) {
            var sortInfoString = JSON.stringify(sortInfo);
            localStorage.setItem(moduleName + "_" + tableName + "_sortInfo", sortInfoString);
        },
        loadSortInfo: function () {
            var sortInfoString = localStorage.getItem(moduleName + "_" + tableName + "_sortInfo");

            if (typeof sortInfoString === "undefined") {
                return null;
            }
            if (sortInfoString === "undefined") {
                return null;
            }
            if (sortInfoString == null) {
                return null;
            }
            if (sortInfoString == '') {

                return null;
            }

            var sortInfo = JSON.parse(sortInfoString);

            if (typeof sortInfo == "undefined") {
                return null;
            }
            if (sortInfo == null) {
                return null;
            }
            
            return sortInfo;
        },

        removeFilter: function () {
            localStorage.removeItem(moduleName + "_" + tableName + "_filters");
        },
        saveFilter: function (filters) {
            var filterString = JSON.stringify(filters);
            localStorage.setItem(moduleName + "_" + tableName + "_filters", filterString);
        },
        loadFilter: function () {
            var filterString = localStorage.getItem(moduleName + "_" + tableName + "_filters");
            if (typeof filterString == "undefined") {
                return null;
            }
            if (filterString == null) {
                return null;
            }
            if (filterString == '') {
                return null;
            }

            var filters = JSON.parse(filterString);

            if (typeof filters == "undefined") {
                return null;
            }
            if (filters == null) {
                return null;
            }

            return filters;
        }
    }
}


export function isNumeric(value) {
    return /^-?\d+$/.test(value);
}


export function additionalParamFilter(additionalParams, paramName) {
    if (typeof additionalParams == 'undefined') {
        return [];
    }
    if (additionalParams == null) {
        return [];
    }
    for (var i = 0; i < additionalParams.length; i++) {
        var additionalParam = additionalParams[i];
        if (typeof additionalParam[paramName] != 'undefined') {
            var isNumber = isNumeric(additionalParam[paramName]);
            additionalParam[paramName] = parseInt(additionalParam[paramName]);
            return [additionalParam];
        }
    }
    return [];
}


export function filterComboData(result, componentID) {
    if (typeof result == 'undefined') {
        return [];
    }
    if (result == null) {
        return [];
    }
    if (typeof result.extra == 'undefined') {
        return [];
    }
    if (result.extra == null) {
        return [];
    }
    if (typeof result.extra[componentID] == 'undefined') {
        return [];
    }
    if (result.extra[componentID] == null) {
        return [];
    }

    return result.extra[componentID];
}


export function getRemoteFilterDefinition(componentID) {
    return {
        name: componentID,
        operator: 'inlist',
        type: 'select',
        value: ''
    };
}

export function setStoredFilters(gridRef, storedFilterValues) {

    //  console.log(gridRef.current);

    if (typeof gridRef == 'undefined') {
        return false;
    }
    if (gridRef == null) {
        return false;
    }

    var hasStoredFilters = true;
    if (typeof storedFilterValues == 'undefined') {
        hasStoredFilters = false;
    }
    if (storedFilterValues == null) {
        hasStoredFilters = false;
    }

    if (hasStoredFilters && Array.isArray(storedFilterValues) == false) {
        hasStoredFilters = false;
    }

    if (hasStoredFilters && storedFilterValues.length == 0) {
        hasStoredFilters = false;
    }

    if (hasStoredFilters == true) {
        for (var i = 0; i < storedFilterValues.length; i++) {
            var storedFilterItem = storedFilterValues[i];
            var hasStoredFilter = typeof storedFilterItem.name != 'undefined';
            hasStoredFilter = hasStoredFilter && storedFilterItem.name != null;
            if (hasStoredFilter == true) {

                if (Array.isArray(storedFilterItem.value)) {
                    for (var index = 0; index < storedFilterItem.value.length; index++) {
                        storedFilterItem.value[index] = storedFilterItem.value[index] * 1;
                    }
                }

                if (storedFilterItem.name != "globalSearch") {
                    gridRef.current.setColumnFilterValue(storedFilterItem.name, storedFilterItem.value, storedFilterItem.operator);
                }
            }
        }

    }


}

export function setSortInfo(gridRef, sortInfo) {
    if (gridRef != null) {
        gridRef.current.setSortInfo(sortInfo);
    }
}
export function setLimitInfo(gridRef, limit) {
    if (gridRef != null) {
        gridRef.current.setLimit(limit);
        gridRef.current.renderPaginationToolbar.apply();
    }
}


export function getValueById(values, id) {

    if (typeof values == 'undefined') {
        return "";
    }
    if (typeof values.length == 0) {
        return "";
    }

    for (var i = 0; i < values.length; i++) {
        var value = values[i];
        if (value.id == id) {
            return value.label;
        }
    }

    return "";
}